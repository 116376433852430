import React from 'react';

const BASE_REPORT_URL = process.env.REACT_APP_BASE_REPORT_URL;

const Report = ({ employerOrg }) => {
	return (
		<div style={{ height: 'calc(100vh - 66px)' }}>
			<iframe
				title="employee-report"
				src={`${BASE_REPORT_URL}/ccreportcgi?func_type=employermenu&w9=${employerOrg.employer_org_id_token}`}
				width="100%"
				height="90%"
				name="reports"
				style={{ border: 0 }}
			/>
		</div>
	);
}

export default Report;
