import React, { useState } from 'react';
import { configure } from 'axios-hooks';
import Axios from 'axios';
import { useCookies } from 'react-cookie';
import add from 'date-fns/add';
import { Layout } from 'antd';

import './App.scss';
import { UserContext } from './context/user';
import Login from './pages/Login';
import EmployeeDashboard from './pages/EmployeeDashboard';

const COOKIES = {
	userId: 'cc-emp-user-id',
	id: 'cc-emp-token',
	email: 'cc-emp-email'
};

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const axios = Axios.create({
	baseURL: BASE_API_URL
});

configure({ axios });

function App() {
	const [cookies, setCookie, removeCookie] = useCookies(['cc-employer']);
	const [isAuthenticated, setIsAuthenticated] = useState(cookies[COOKIES.id] && cookies[COOKIES.userId]);
	const [userId, setUserId] = useState(cookies[COOKIES.userId]);

	if (cookies[COOKIES.id] && cookies[COOKIES.userId]) {
		axios.defaults.headers.common['Authorization'] = cookies[COOKIES.id];
	}

	const onAuthenticated = (authData) => {
		axios.defaults.headers.common['Authorization'] = authData.id;

		const createdAt = new Date(authData.created);
		const expires = add(createdAt, { seconds: authData.ttl });
		setCookie(COOKIES.id, authData.id, { expires });
		setCookie(COOKIES.userId, authData.userId, { expires });
		setUserId(authData.userId);

		if (authData.remember) {
			setCookie(COOKIES.email, authData.email, { expires: add(new Date(), { days: 90 }) });
		} else {
			removeCookie(COOKIES.email);
		}

		setIsAuthenticated(true);
	};

	const handleLogout = () => {
		removeCookie(COOKIES.id);
		removeCookie(COOKIES.userId);
		delete axios.defaults.headers.common['Authorization'];
		setIsAuthenticated(false);
	};

	return (
		<Layout>
			{isAuthenticated ? (
				<UserContext.Provider value={{ id: userId }} >
					<EmployeeDashboard onLogout={handleLogout} />
				</UserContext.Provider>
			) : (
				<Layout.Content>
					<Login onAuthenticated={onAuthenticated} email={cookies[COOKIES.email]} />
				</Layout.Content>
			)}
		</Layout>
	);
}

export default App;
