import React from 'react';
import { Table, Popconfirm, Button } from 'antd';
import StatusCell from './components/StatusCell';

// active_ind: true
// department: "Information Technology"
// email_personal: "testpersonal@careconvene.com"
// email_work: "testwork@careconvene.com"
// employee_id: "FSmith2912"
// employee_or_beneficiary: "E"
// employer_org_id: 1
// id: 1
// location: "Headquarters"
// manager: "The Manager"
// name_first: "Frederick"
// name_last: "Smith"
// phone_mobile: "5555555555"
// title: "Manager"

const DATA_COLUMNS = [{
	title: 'Name',
	key: 'name',
	render: (text, record) => `${record.name_first} ${record.name_last}`
}, {
	title: 'Employee #',
	dataIndex: 'employee_id',
	key: 'employee_id'
}, {
	title: 'Phone',
	dataIndex: 'phone_mobile',
	key: 'phone_mobile'
}, {
	title: 'Department',
	dataIndex: 'department',
	key: 'department'
}, {
	title: 'Location',
	dataIndex: 'location',
	key: 'location'
}, {
	title: 'Status',
	key: 'status',
	render: (text, record) => (
		<StatusCell empData={record} />
	)
}];

const EmployeeList = ({ loading, employees = [], onDelete }) => {
	const columns = [
		...DATA_COLUMNS,
		{
			title: 'Actions',
			dataIndex: 'actions',
			render: (text, record) => (
				<Popconfirm title={`Remove ${record.name_first} ${record.name_last}?`} onConfirm={() => onDelete(record)}>
					<Button type="link">Delete</Button>
				</Popconfirm>
			)
		}
	];
	return (
	   <Table
			loading={loading}
			columns={columns}
			dataSource={employees.map(row => ({ key: `${row.id}`, ...row }))}
	   />
	);
}

export default EmployeeList;
