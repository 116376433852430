import React, { useContext, useState } from 'react';
import useAxios from 'axios-hooks';
import { Layout, Row, Col, Menu, Button, Typography } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

import { UserContext } from '../../context/user';
import EmployerOrg from './EmployerOrg';
import Report from './Report';

const EmployeeDashboard = ({ onLogout }) => {
    const user = useContext(UserContext);
    const [route, setRoute] = useState('dashboard');
    const [{ data: userData, error }] = useAxios(`/${user.id}`);
	const [{ data: employerOrg, /* loading, error */ }] = useAxios(`/${user.id}/employer_org`);
    // TODO put userData in UserContext

    const [, logout] = useAxios({ url: '/logout', method: 'POST' }, { manual: true });

    const handleLogoutClick = () => {
        logout()
            .finally(() => {
                onLogout();
            });
    };

    const handleMenuClick = ({ key }) => {
        setRoute(key);
    };

    return (
        <>
            <Layout.Header>
                <Row gutter={16}>
                    <Col flex="auto">
                        <Menu mode="horizontal" defaultSelectedKeys={[route]} onClick={handleMenuClick}>
                            <Menu.Item key="dashboard">Employee Dashboard</Menu.Item>
                            <Menu.Item key="reports">Reports</Menu.Item>
                        </Menu>
                    </Col>
                    <Col>
                        {userData && (
                            <Typography.Text style={{ color: '#ffffff', fontWeight: 'bold' }}>{userData.name_first} {userData.name_last}</Typography.Text>
                        )}
                    </Col>
                    <Col>
                        <Button
                            type="text"
                            icon={<LogoutOutlined style={{ color: '#ffffff' }} />}
                            style={{ background: 'inherit', border: 'none' }}
                            onClick={handleLogoutClick}
                            title="Logout"
                        />
                    </Col>
                </Row>
            </Layout.Header>
            <Layout.Content style={{ padding: '0 50px' }}>
                {error ? (
                    <div>Error loading user account: {error}</div>
                ) : (
                    <>
                        {(route === 'dashboard') && <EmployerOrg employerOrg={employerOrg} />}
                        {(route === 'reports') && <Report employerOrg={employerOrg} />}
                    </>
                )}
            </Layout.Content>
        </>
     );
}

export default EmployeeDashboard;
