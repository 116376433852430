import React, { useContext, useState } from 'react';
import useAxios from 'axios-hooks';
import { Row, Col, Button, Typography, message } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';

import { UserContext } from '../../context/user';
import EmployeeList from './EmployeeList';
import AddEmployeeModal from './AddEmployeeModal';

const EmployerOrg = ({ employerOrg = {} }) => {
	const user = useContext(UserContext);
	// const [{ data = {}, /* loading, error */ }] = useAxios(`/${user.id}/employer_org`);
	const [, executeAdd] = useAxios({ url: `/${user.id}/add-employee`, method: 'POST' }, { manual: true });
	const [{ data: employeeList, loading, /* error */ }, getEmployees] = useAxios(`/${user.id}/list-employees`);
	const [, executeRemove] = useAxios({ url: `/${user.id}/remove-employee`, method: 'POST' }, { manual: true });
	// const [{ }] = useAxios(`/${user.id}/list-available-screenings`);

	const [isAddEmployeeModalVisible, setIsAddEmployeeModalVisible] = useState(false);

	const handleSave = (empData) => {
		executeAdd({ data: empData })
			.then(({ data }) => {
				if (data && 'employee created' === data.status) {
					message.success(`${empData.name_first} ${empData.name_last} successfully added`);
				}
				setIsAddEmployeeModalVisible(false);
				getEmployees();
			})
			.catch((err) => {
				setIsAddEmployeeModalVisible(false);
				message.error(`Failed to add employee: ${err.message}`);
			});
	};

	const handleDelete = (employee) => {
		executeRemove({ data: { employee_id: employee.id } })
			.then(({ data }) => {
				if (data && 'employee removed' === data.status) {
					message.success(`${employee.name_first} ${employee.name_last} successfully removed`);
				}
				getEmployees();
			})
			.catch((err) => {
				message.error(`Failed to remove employee: ${err.message}`);
			});
	};

	return (
		<>
			<AddEmployeeModal
				visible={isAddEmployeeModalVisible}
				onCancel={() => setIsAddEmployeeModalVisible(false)}
				onSave={handleSave}
			/>
			<Row style={{ paddingTop: '25px' }}>
				<Col flex="auto">
					<Typography.Title level={2} className="employer-name">{employerOrg.name}</Typography.Title>
				</Col>
				<Col>
					<Button icon={<UserAddOutlined />} onClick={() => setIsAddEmployeeModalVisible(true)}>Add Employee</Button>
				</Col>
			</Row>
			<EmployeeList employees={employeeList && employeeList.status} loading={loading} onDelete={handleDelete} />
		</>
	);
}

export default EmployerOrg;
