import React from 'react';
import { Modal, Form, Input } from 'antd';

const AddEmployeeModal = ({ visible, onCancel, onSave }) => {
	const [form] = Form.useForm();

	const handleCancel = () => {
		form.resetFields();
		onCancel();
	};

	const handleOk = () => {
		// collect form data and pass to parent
		form.validateFields()
			.then((values) => {
				form.resetFields();
				onSave(values);
			})
			.catch(info => console.log(info));
	}

	return (
		<Modal
			visible={visible}
			title="Add Employee"
			okText="Add"
			onOk={handleOk}
			onCancel={handleCancel}
		>
			<Form form={form} size="small" labelCol={{ span: 8 }}>
				<Form.Item
					label="First Name"
					name="name_first"
					required
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Last Name"
					name="name_last"
					required
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Title"
					name="title"
					required
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Work Email"
					name="email_work"
					required
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Personal Email"
					name="email_personal"
					required
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Mobile #"
					name="phone_mobile"
					required
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Location"
					name="location"
					required
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Department"
					name="department"
					required
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Manager"
					name="manager"
					required
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Employee #"
					name="employee_id"
					required
				>
					<Input />
				</Form.Item>
			</Form>
		</Modal>
	 );
}

export default AddEmployeeModal;
