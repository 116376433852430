import React from 'react';

const StatusCell = ({ empData }) => {
	if (empData.cc_registered_ind) {
		return (
			<span>Signed Up</span>
		);
	}
	return (
		<span>Not Signed Up</span>
	);
}

export default StatusCell;
