import React from 'react';
import useAxios from 'axios-hooks';
import { Typography, Form, Input, Button, Checkbox } from 'antd';

const Login = ({ onAuthenticated, email = '' }) => {
	const [{ loading, error }, execute] = useAxios({ url: '/login', method: 'POST' }, { manual: true });

	const onFinish = (values) => {
		execute({ data: { ...values, realm: 'employer_admin' } })
			.then(({ data }) => {
				onAuthenticated({ ...data, ...values });
			});
	};

	return (
		<div className="login">
			<div className="login-box">
				<img className="logo" src="img/cc-employer.png" alt="Care Convene Logo" />
				<Typography.Title level={2}>Welcome</Typography.Title>
				{error && (
					<p><Typography.Text type="danger">{error.message}</Typography.Text></p>
				)}
				<Form layout="vertical" onFinish={onFinish} initialValues={{ remember: true, email }}>
					<Form.Item
						name="email"
						label="Email"
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="password"
						label="Password"
					>
						<Input.Password type="password" />
					</Form.Item>
					<Form.Item name="remember" valuePropName="checked">
						<Checkbox>Remember me</Checkbox>
					</Form.Item>
					<Form.Item>
						<Button type="primary" block htmlType="submit" loading={loading}>
							Login
						</Button>
					</Form.Item>
				</Form>
				<p style={{ textAlign: 'center' }}>&copy; {new Date().getFullYear()} Care Convene</p>
			</div>
		</div>
	 );
}

export default Login;
